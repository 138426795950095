import React from "react";
import { Formik, Form, Field } from "formik";
import { Button, Input } from "@nextui-org/react";
import { client, Customerservice } from "../../../utils/client";

type BudgetFormProps = {
  service: Customerservice;
};

export default function BudgetForm({ service }: BudgetFormProps) {
  const onSubmit = async (data: { budgets: number[] }) => {
    // Assume service has a "budgets" array of 12 numbers (one per month)
    await client.service("services").patch(service._id.toString(), { budgets: data.budgets }); // Update budget with your service method
  };

  return (
    <Formik
      initialValues={{
        budgets: service.budgets || Array(12).fill(0), // Initialize with current budgets or default 0
      }}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleBlur, dirty }) => (
        <Form>
          <div className="grid grid-cols-3 gap-4">
            {["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].map(
              (month, index) => (
                <div key={index}>
                  <label>{month}</label>
                  <Input
                    fullWidth
                    name={`budgets[${index}]`}
                    type="number"
                    label={`Budget for ${month}`}
                    value={values.budgets[index].toString()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              )
            )}
          </div>
          <div className="flex justify-end mt-5">
            {dirty && (
              <Button type="submit" color="primary">
                Save
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
