import { Formik, Form } from "formik";
import { Customer, Customerservice, client } from "../../utils/client";
import { Button, Input, Textarea } from "@nextui-org/react";
import SelectUser from "../../components/Forms/Fields/SelectUser";
import { useModal } from "../../hooks/useModal";
import DatePicker from "../../components/Forms/Fields/DatePicker";

type ServiceFormProps = {
  service: Customerservice;
};

export default function ServiceForm({ service }: ServiceFormProps) {
  const { closeModal } = useModal();
  const onSubmit = async (data: Customerservice) => {
    await client.service("services").patch(service._id.toString(), data);
    closeModal();
  };
  return (
    <Formik
      initialValues={
        {
          description: service.description || "",
          value: service.value || 0,
          startDate: service.startDate || new Date().getTime(),
        } as Customerservice
      }
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleBlur, setFieldValue, dirty }) => (
        <Form>
          <div className="space-y-2">
            <Textarea
              fullWidth
              name="description"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Beskrivning"
              value={values.description || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="value"
              labelPlacement="inside"
              variant="flat"
              type="number"
              label="Värde"
              value={values.value.toString() || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <DatePicker
              value={values.startDate}
              label="Startdatum"
              onChange={(newTimestamp) => setFieldValue("startDate", newTimestamp)} // Update Formik's value
            />
          </div>
          <div className="flex justify-end mt-5">
            {dirty && (
              <Button type="submit" color="primary">
                Spara
              </Button>
            )}
            <Button onClick={closeModal} type="button" className="ml-2">
              Avbryt
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
