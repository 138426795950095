import { Tooltip, Bar, BarChart, ResponsiveContainer, XAxis } from "recharts";
import { Customerservice } from "../../../utils/client";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { useModal } from "../../../hooks/useModal";
import BudgetForm from "./BudgetForm";

type NotesChartProps = {
  service: Customerservice;
};

export default function BudgetChart({ service }: NotesChartProps) {
  const { showModal } = useModal();

  // Sample months data
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Mapping service.budgets to chart data
  const chartData = months.map((month, index) => ({
    month,
    budget: service.budgets ? service.budgets[index] : 0, // Ensure we handle if budgets are not provided
  }));

  // Custom Tooltip
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-4 bg-white border custom-tooltip rounded-xl border-grayLight">
          <p className="label text-gray">Budget: {`${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="relative w-full h-auto p-6 rounded-lg panel">
      <div className="my-2">
        <div className="text-small text-inherit">Budget</div>
      </div>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={chartData}>
            <Tooltip content={<CustomTooltip />} />
            <Bar radius={[5, 5, 0, 0]} barSize={20} dataKey="budget" fill="#D5A438" />
            <XAxis
              padding={{ left: 20, right: 20 }}
              tick={{ fill: "#64748b", fontSize: "14", fontWeight: 300 }}
              axisLine={false}
              tickLine={false}
              dataKey="month"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="absolute top-0 right-0 ">
        <EllipsisHorizontalIcon
          onClick={() => showModal(<BudgetForm service={service} />, "Redigera budget")}
          className="w-6 h-6 mt-3 mr-4 cursor-pointer text-foreground-400 hover:text-gray"
        />
      </div>
    </div>
  );
}
